<script setup>
const { authUser, logout, isAuthenticated, stopEmulation, emulatedUserId } = useAuth()
const { $notify } = useNuxtApp()
import { startCase } from 'lodash'

const emit = defineEmits(['toggleSidebar'])
const router = useRouter()

const { hasFilters } = useFilters()

const data = reactive({
    document: null,
    forceChangePassword: false
})

const openChangePassword = ref()

const password_expire = authUser.value?.account?.password_expire

if (password_expire) {
  const password_expire_date = new Date(password_expire)
  const today = new Date()
  const in_ten_days = new Date()
  in_ten_days.setDate(today.getDate() + 10)
  
  if (password_expire_date<today) {
    openChangePassword.value = true
    data.forceChangePassword = true
  } else if (password_expire_date<in_ten_days) {
    const days = Math.round((password_expire_date.getTime()-today.getTime())/(1000 * 3600 * 24))
    nextTick(() => $notify(`Your password is expiring in ${days} days, please change it.`, 'warning'))
  }
}

if (password_expire && new Date(password_expire)<new Date()) {
  openChangePassword.value = true
  data.forceChangePassword = true
}

import links from './settings'


function stopEmulationAndRefresh() {
      stopEmulation()
      router.push(`/dashboard`)
      // location.assign('/#/dashboard')
      // location.reload()
}

</script>
<template>
  <v-app-bar app color="primary" v-if="isAuthenticated">

    <AppChangePassword v-model="openChangePassword" :force="data.forceChangePassword"/>

    <v-app-bar-nav-icon @click="emit('toggleSidebar')"/>
    <v-toolbar-title variant="primary">
      <strong>{{ startCase($route?.meta?.title ?? $route?.name ?? '') }}</strong>
    </v-toolbar-title>

    <v-btn dark variant="flat" class="mr-5" v-if="emulatedUserId" color="red" @click="stopEmulationAndRefresh">ATTENTION! You are in user emulation mode. Click to CANCEL</v-btn>
    
    <CoreSearchDocumentSchema id="globalSearchDocument" v-if='hasFilters' v-model="data.document" navbar class="mr-2" style="width: 400px;" clearable/>
    <v-btn v-show='$can("read", "global_settings")' class='mr-1' id="settings-menu-activator" icon='mdi-cog' />
    <v-btn id="menu-activator" append-icon='mdi-user'  size="large">{{ authUser?.account?.name }}</v-btn>

    <v-menu activator="#settings-menu-activator" bottom left offset-y transition="slide-y-transition">
      <v-card width="250" variant='outlined' color='grey-lighten-2'>
          <v-list>
            <v-list-item :id="`setting_${link.entity}`" v-for="(link, id) in links" :key="id" :title="link.text" :to="link.to"
              :prepend-icon="link?.icon" />
          </v-list>
      </v-card>
    </v-menu>    
    
    <v-menu activator="#menu-activator" bottom left offset-y transition="slide-y-transition">
      <v-card width="250" variant='outlined' color='grey-lighten-2'>
        <v-list>
          <v-list-item>
            <v-list-item-title>{{ authUser.account.name }} {{ authUser.account.surname }}</v-list-item-title>
            <v-list-item-subtitle>[{{ authUser.account.system_role }}]</v-list-item-subtitle>
          </v-list-item>
          <v-list-item @click="openChangePassword = true" prepend-icon="mdi-key">Change password</v-list-item>
          <v-list-item @click="logout" prepend-icon="mdi-logout">Logout</v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>  
  
