<script setup>

import zxcvbn from 'zxcvbn'
const { $notify } = useNuxtApp()
const { modelValue, force } = defineProps({ modelValue: Boolean, force: { type: Boolean, default: false } })
const emit = defineEmits('update:modelValue')
const dataForm = reactive({
    oldpass: '',
    newpass: '',
    confirmpass: ''
})

const data = reactive({
    valid: false,
    loading: false,
    error: ''
})

const showNewPassword = ref(false)
const showOldPassword = ref(false)
const showConfirmPassword = ref(false)

async function changePassword () {
    data.loading = true
    data.error = ''
    try {
        await useAuth().changePassword(dataForm)
        $notify('Password correctly updated', 'success')
        emit('update:modelValue', false)
        Object.assign(dataForm, { oldpass: '', newpass: '', confirmpass: '' })
    } catch (e) {
        data.error = e?.response?._data?.message ?? e
    }
    data.loading = false
}

function strongPassword (value) {
    const ret = zxcvbn(value)
    return ret.score >= 3 || ret.feedback?.warning || ret.feedback?.suggestions[0]
}

function differentPassword (value) {
    return dataForm.oldpass !== dataForm.newpass || 'The new password must be different from the old one'
}

</script>
<template>
    <v-dialog :model-value="modelValue" @update:modelValue="e => $emit('update:modelValue', e)" width="400" :persistent="force">
        <CoreCard title="Change Password" @close="() => $emit('update:modelValue', false)" :closable="!force" icon='mdi-key-outline' :loading='data.loading' :disabled="!data.valid || data.loading" @confirm="changePassword" @cancel="$emit('update:modelValue', false)">
            <v-alert class="mb-6" v-if="force">Your password is older than 90 days and you need to change it.</v-alert>
            <v-form v-model="data.valid" @submit.prevent="changePassword">
                <v-text-field class="mb-2"
                    v-model="dataForm.oldpass"
                    label="Current password"
                    :type="showOldPassword ? 'text' : 'password'"
                    @click:append-inner="showOldPassword = !showOldPassword"
                    prepend-inner-icon="fluent:password-20-regular"
                    :append-inner-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[$valid.required]" />

                <p class="text-medium-emphasis">Please enter a password that is at least 8 characters long and includes special characters, uppercase letters, and numbers.</p><br/>
                    
                <v-text-field class="mb-2"
                    v-model='dataForm.newpass'
                    label='New password'
                    :type="showNewPassword ? 'text' : 'password'"
                    @click:append-inner="showNewPassword = !showNewPassword"
                    prepend-inner-icon="mdi-key" 
                    :append-inner-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[$valid.required, $valid.password, differentPassword, strongPassword]" />

                <v-text-field
                    v-model='dataForm.confirmpass' 
                    label='Confirm new password' 
                    :type="showConfirmPassword ? 'text' : 'password'"
                    prepend-inner-icon="mdi-key" 
                    @click:append-inner="showConfirmPassword = !showConfirmPassword"
                    :append-inner-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[$valid.required, $valid.confirm(dataForm.newpass)]" />

                <v-alert type='warning' v-if="data.error">{{ data.error }}</v-alert>
            </v-form>
        </CoreCard>
    </v-dialog>
</template>
